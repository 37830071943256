import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { useFilters } from 'core/contexts/FilterContext'
import { handleSearchParam } from 'core/utils'
import { requestAccessToJournal, useJournalsSharedWithMe } from 'core/api/journals'
import { useNotifications } from 'shared/Notifications'
import { AxiosError } from 'axios'
import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'

export default function useHomePageSearchParamsResolver() {
  const resolved = useInitialResolving()
  useSyncSearchParamsWithFilters({ skip: !resolved })
}

function useInitialResolving() {
  const resolvedMap = useRef({
    modal: false,
    filters: false,
    journals: false,
    sharedJournals: false,
  })

  useResolvingModal({
    skip: resolvedMap.current.modal,
    markAsResolved: () => (resolvedMap.current.modal = true),
  })
  useResolvingFilters({
    skip: resolvedMap.current.filters,
    markAsResolved: () => (resolvedMap.current.filters = true),
  })
  useResolvingJournals({
    skip: resolvedMap.current.journals,
    markAsResolved: () => (resolvedMap.current.journals = true),
  })
  useResolvingSharedJournals({
    skip: resolvedMap.current.journals,
    markAsResolved: () => (resolvedMap.current.sharedJournals = true),
  })

  return resolvedMap.current.modal && resolvedMap.current.filters && resolvedMap.current.journals
}

function useResolvingModal(params: { skip: boolean; markAsResolved: () => void }) {
  const { openAddDialog } = useJournalsApi()
  const navigate = useNavigate()

  useEffect(() => {
    if (params.skip) return
    let searchParams = new URLSearchParams(window.location.search)
    searchParams = handleSearchParam(['modal'], searchParams, ([modalName]) => {
      if (!modalName) return
      if (modalName === 'add_journal') openAddDialog()
    })

    params.markAsResolved()
    navigate({ search: searchParams.toString() }, { replace: true })
  })
}

function useResolvingFilters(params: { skip: boolean; markAsResolved: () => void }) {
  const navigate = useNavigate()

  const { setFiltersBySearchParams } = useFilters()

  useEffect(() => {
    if (params.skip) return
    let searchParams = new URLSearchParams(window.location.search)
    searchParams = setFiltersBySearchParams(searchParams)
    params.markAsResolved()
    navigate({ search: searchParams.toString() }, { replace: true })
  })
}

function useResolvingJournals(params: { skip: boolean; markAsResolved: () => void }) {
  const navigate = useNavigate()
  const journalsAll = useJournalsAll()
  const { setJournalBySearchParams } = useFilters()

  useEffect(() => {
    if (params.skip) return
    if (journalsAll.isLoading) return
    if (!journalsAll.data) return

    let searchParams = new URLSearchParams(window.location.search)
    searchParams = setJournalBySearchParams(searchParams, journalsAll.data)

    params.markAsResolved()
    navigate({ search: searchParams.toString() }, { replace: true })
  })
}

function useResolvingSharedJournals(params: { skip: boolean; markAsResolved: () => void }) {
  const navigate = useNavigate()
  const { showNotification } = useNotifications()
  const journalsSharedWithMe = useJournalsSharedWithMe()
  const [filters, setFilters] = useRecoilState(filterState)

  useEffect(() => {
    if (params.skip) return
    let searchParams = new URLSearchParams(window.location.search)

    searchParams = handleSearchParam(
      ['sharedJournalId', 'sharingToken'],
      searchParams,
      ([journalId, sharingToken]) => {
        if (!journalId || !sharingToken) return
        requestAccessToJournal(journalId, sharingToken)
          .then((response) => {
            showNotification({ text: 'Journal added successfully', type: 'success' })
            return journalsSharedWithMe.mutate()
          })
          .then(() => {
            setFilters({ ...filters, journalId: journalId })
          })
          .catch((err) => {
            let text = 'Something went wrong. Journal was not added'
            if (
              err instanceof AxiosError &&
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              text = err.response.data.message
            }

            showNotification({
              text,
              type: 'error',
            })
          })
      }
    )
    params.markAsResolved()
    navigate({ search: searchParams.toString() }, { replace: true })
  })
}

/** Read filters & update search params on each change */
function useSyncSearchParamsWithFilters({ skip = false }) {
  const { filtersSearchParams } = useFilters()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (skip) return
    navigate({ search: filtersSearchParams.toString() }, { replace: true })
  }, [filtersSearchParams, navigate, skip, location.pathname])
}
